<script>
export default {
  name: 'LogoJewelGrande',
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="50 0 420 240"
    :aria-labelledby="label"
    role="presentation"
    :class="{ 'fill-current': fill }"
  >
    <rect
      x="119.7"
      y="191.1"
      :fill="fill ? '#707372' : 'currentColor'"
      width="283.8"
      height="0.6"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M58.9,169.8h2.9l0,0.4c0,0.4,0,0.8,0.1,1.1c0,1.6,0.8,2.4,2.2,2.4c2.5,0,3.3-1.5,3.3-6.5v-21.2
          c0-1.8-0.1-2.8-0.4-3.2c-0.2-0.4-1.1-0.6-2.4-0.7l-0.4,0v-1.8h12.5v1.8l-0.4,0c-1.3,0.1-2.2,0.3-2.4,0.7
          c-0.2,0.4-0.4,1.4-0.4,3.2v19.1c0,3.8-0.7,6.5-2.2,8.1c-1.5,1.6-4,2.4-7.5,2.4c-1.4,0-3.1-0.2-4.9-0.7V169.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M109,169.8H86.6V168l0.4,0c1.3-0.1,2.2-0.3,2.4-0.7c0.3-0.4,0.4-1.5,0.4-3.3v-17.9c0-1.8-0.1-2.8-0.4-3.2
          c-0.2-0.4-1.1-0.6-2.4-0.7l-0.4,0v-1.8h21.6v5.3h-2.9l0-0.4c0-0.7,0-1.1,0-1.2c0-1.5-0.4-1.8-2.9-1.8h-6.3v11.2h4.1
          c1.6,0,2.5,0,2.7-0.1c0.2-0.1,0.4-0.7,0.4-1.9l0-0.4h2.1v6.4h-2.1l0-0.4c0-1.1-0.2-1.6-0.6-1.8c-0.4-0.1-1.2-0.2-2.5-0.2h-4.2
          v8.8c0,2.2,0.2,3.4,0.8,3.5c0.4,0.1,1.4,0.2,3.2,0.2h2.4c2,0,3.1-0.1,3.4-0.3c0.2-0.2,0.4-1.1,0.5-2.8v-0.4h2.9V169.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M128,170.2l-6.8-24c-0.6-2-1.1-3.1-1.3-3.3c-0.2-0.3-0.9-0.5-2-0.6l-0.4,0v-1.8h11.5v1.8h-0.4
          c-1.6,0-2.4,0.2-2.4,0.7c0,0.6,0.2,1.7,0.7,3.2l3.9,13.6l6.1-19.1h2.2l6.5,20.2l4.7-14.7c0.1-0.3,0.2-0.9,0.4-1.7
          c0.2-0.8,0.3-1.3,0.3-1.5c0-0.6-0.7-0.9-2.2-0.9h-0.4v-1.8h7.9v1.8l-0.4,0c-0.4,0-0.7,0-0.9,0c-0.7,0-1.1,0.1-1.3,0.4
          c-0.1,0.2-0.6,1.4-1.3,3.6l-7.6,23.9h-2l-6.6-20.4l-6.5,20.4H128z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M186.2,169.8h-22.4V168l0.4,0c1.3-0.1,2.2-0.3,2.4-0.7c0.3-0.4,0.4-1.5,0.4-3.3v-17.9
          c0-1.8-0.1-2.8-0.4-3.2c-0.2-0.4-1.1-0.6-2.4-0.7l-0.4,0v-1.8h21.6v5.3h-2.9l0-0.4c0-0.7,0-1.1,0-1.2c0-1.5-0.4-1.8-2.9-1.8h-6.3
          v11.2h4.1c1.6,0,2.5,0,2.7-0.1c0.2-0.1,0.4-0.7,0.4-1.9l0-0.4h2.1v6.4h-2.1l0-0.4c0-1.1-0.2-1.6-0.6-1.8
          c-0.4-0.1-1.2-0.2-2.5-0.2H173v8.8c0,2.2,0.2,3.4,0.8,3.5c0.4,0.1,1.4,0.2,3.2,0.2h2.4c2,0,3.1-0.1,3.4-0.3
          c0.2-0.2,0.4-1.1,0.5-2.8v-0.4h2.9V169.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M219,169.8h-22.6V168l0.4,0c1.3-0.1,2.2-0.3,2.4-0.7c0.3-0.4,0.4-1.5,0.4-3.3v-17.9
          c0-1.8-0.1-2.8-0.4-3.2c-0.2-0.4-1.1-0.6-2.4-0.7l-0.4,0v-1.8h12.5v1.8l-0.4,0c-1.3,0.1-2.2,0.3-2.4,0.7
          c-0.2,0.4-0.4,1.4-0.4,3.2V164c0,2.2,0.2,3.4,0.8,3.5c0.4,0.1,1.4,0.2,3.2,0.2h2.4c1,0,2,0,2.8-0.2c0.4,0,0.7-0.2,0.9-0.5
          c0.1-0.3,0.2-1.6,0.2-3.9l0-0.4h2.9V169.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M271.1,169.2c-4.1,0.9-7.4,1.3-10.1,1.3c-4.7,0-8.4-1.4-11.1-4.2c-2.7-2.8-4.1-6.6-4.1-11.3
          c0-9.8,5.6-15.4,15.6-15.4c3.1,0,6.3,0.4,9.6,1.3v5.8h-2.9v-0.4c0-1.6-0.2-2.6-0.7-3.1c-1.3-1.2-3.3-1.8-6.1-1.8
          c-6,0-9,4.5-9,13.5c0,9,3.2,13.5,9.7,13.5c0.9,0,1.9-0.2,2.9-0.5v-5.5c0-1.8-0.1-2.8-0.4-3.2c-0.2-0.4-1.1-0.6-2.4-0.7l-0.4,0
          v-1.8h12.5v1.8l-0.4,0c-1.3,0-2.1,0.3-2.4,0.7c-0.3,0.4-0.4,1.5-0.4,3.2V169.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M293,156.9v7.1c0,1.7,0.1,2.8,0.3,3.3c0.2,0.4,0.9,0.6,2,0.7l0.4,0v1.8h-11.8V168l0.4,0
          c1.3-0.1,2.2-0.3,2.4-0.7c0.3-0.4,0.4-1.5,0.4-3.3v-17.9c0-1.8-0.1-2.8-0.4-3.2c-0.2-0.4-1.1-0.6-2.4-0.7l-0.4,0v-1.8h8.6
          l3.7-0.1l1.8,0c6.1,0,9.1,2.5,9.1,7.5c0,4.1-1.9,6.8-5.7,8.4l5.7,8c1.4,1.9,2.3,3,2.7,3.3s1.1,0.5,2.2,0.5l0.4,0v1.8h-8.8
          l-8.1-12.9H293z M293,154.9h1.1c4.1,0,6.8-2.6,6.8-6.9c0-3.8-1.9-5.7-5.7-5.7H293V154.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M325.3,160.9l-1.5,3.2c-0.7,1.6-1.1,2.6-1.1,3.2c0,0.4,0.4,0.7,1.3,0.7c0.1,0,0.4,0,0.8,0l0.4,0v1.8h-7.9
          V168l0.4,0c1.1,0,1.7-0.2,2-0.5c0.3-0.3,0.9-1.4,1.8-3.4l11-23.9h2l11,23.9c1,2,1.6,3.1,1.9,3.4s1,0.4,2.1,0.5l0.2,0v1.8h-12.3
          V168l0.4,0c1.7-0.1,2.5-0.4,2.6-0.8c0-0.4-0.4-1.5-1.1-3.2l-1.5-3.1H325.3z M326.2,158.9h10.5l-5.3-11.4L326.2,158.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M379.5,170.2l-16.4-22.3V164c0,1.8,0.1,2.9,0.4,3.3c0.3,0.4,1.1,0.6,2.4,0.7l0.4,0v1.8H357V168l0.4,0
          c1.3-0.1,2.1-0.3,2.4-0.7s0.4-1.5,0.4-3.3v-17.9c0-1.8-0.1-2.8-0.4-3.2c-0.2-0.4-1.1-0.6-2.4-0.7l-0.4,0v-1.8h7.4l16.2,22.2
          v-16.5c0-1.8-0.1-2.8-0.4-3.2c-0.2-0.4-1.1-0.6-2.4-0.7l-0.4,0v-1.8h9.3v1.8l-0.4,0c-1.3,0.1-2.1,0.3-2.4,0.7
          c-0.3,0.4-0.4,1.4-0.4,3.2v24.1H379.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M397.2,169.8V168l0.4,0c1.3-0.1,2.2-0.3,2.4-0.7c0.3-0.4,0.4-1.5,0.4-3.3v-17.9c0-1.8-0.1-2.8-0.4-3.2
          c-0.2-0.4-1.1-0.6-2.4-0.7l-0.4,0v-1.8h9.7l4.3,0l1.5,0c4.6,0,8.2,1.2,10.7,3.7c2.5,2.4,3.7,5.9,3.7,10.5c0,4.6-1.3,8.3-3.8,11.2
          c-2.5,2.8-5.9,4.2-10.1,4.2H397.2z M406.5,142.3V164c0,1.6,0.2,2.6,0.7,3.1c0.5,0.4,1.7,0.6,3.6,0.6c6.5,0,9.7-4.4,9.7-13.2
          c0-4.4-0.9-7.6-2.7-9.4c-1.8-1.8-4.8-2.8-9.1-2.8H406.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M459.3,169.8h-22.4V168l0.4,0c1.3-0.1,2.2-0.3,2.4-0.7c0.3-0.4,0.4-1.5,0.4-3.3v-17.9
          c0-1.8-0.1-2.8-0.4-3.2c-0.2-0.4-1.1-0.6-2.4-0.7l-0.4,0v-1.8h21.6v5.3h-2.9l0-0.4c0-0.7,0-1.1,0-1.2c0-1.5-0.4-1.8-2.9-1.8h-6.3
          v11.2h4.1c1.6,0,2.5,0,2.7-0.1c0.2-0.1,0.4-0.7,0.4-1.9l0-0.4h2.1v6.4h-2.1l0-0.4c0-1.1-0.2-1.6-0.6-1.8
          c-0.4-0.1-1.2-0.2-2.5-0.2h-4.2v8.8c0,2.2,0.2,3.4,0.8,3.5c0.4,0.1,1.4,0.2,3.2,0.2h2.4c2,0,3.1-0.1,3.4-0.3
          c0.2-0.2,0.4-1.1,0.5-2.8v-0.4h2.9V169.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M253,112c-0.1,0-0.2,0-0.4-0.1l-10.2-9c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l10.2,9
c0.2,0.2,0.2,0.5,0,0.7C253.3,111.9,253.2,112,253,112z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M242.6,103.7c-0.1,0-0.1,0-0.2,0c-2-0.7-4-1.3-5.8-1.9c-2.7-0.9-5.3-1.7-8.1-2.6
c-0.3-0.1-0.4-0.4-0.3-0.7c0.1-0.3,0.4-0.4,0.7-0.3c2.8,0.9,5.3,1.7,8,2.6c1.9,0.6,3.8,1.2,5.8,1.9c0.3,0.1,0.4,0.4,0.3,0.7
C243.1,103.5,242.9,103.7,242.6,103.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M266.9,109.2c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.3-0.3,7-1.9
c3.2-0.8,7.6-1.8,10.1-2.3c0.3-0.1,0.6,0.1,0.6,0.4c0.1,0.3-0.1,0.6-0.4,0.6c-4.8,0.9-15.9,3.6-16.7,4
C267.1,109.2,267,109.2,266.9,109.2z M267.2,109.1L267.2,109.1L267.2,109.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M259.8,112.6c-0.8,0-1.6,0-2.4-0.1c-11.6-0.6-22.3-5.8-30.1-14.4c-7.8-8.7-11.7-19.9-11.1-31.5
s5.8-22.3,14.4-30.1c8.7-7.8,19.9-11.7,31.5-11.1c11.6,0.6,22.3,5.8,30.1,14.4c7.8,8.7,11.7,19.9,11.1,31.5
c-0.6,11.6-5.8,22.3-14.4,30.1C280.9,108.7,270.6,112.6,259.8,112.6z M259.8,26.6c-22.3,0-41,17.5-42.2,40
c-1.3,23.3,16.7,43.3,40,44.6c11.3,0.6,22.1-3.2,30.6-10.8c8.4-7.6,13.4-17.9,14-29.2l0.7,0l-0.7,0c1.3-23.3-16.7-43.3-40-44.6
C261.3,26.6,260.5,26.6,259.8,26.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M242.7,108.9c-0.3,0-0.5-0.2-0.5-0.5c-0.3-7.5,0.8-15.1,3-22c2.6-7.8,6.6-14.6,11.9-20
c4.8-4.8,10.6-8.6,17.2-11.1c7.3-2.8,15.7-4.1,25-3.9c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5l0,0
c-17.6-0.3-31.5,4.6-41.4,14.6c-9.9,10-15.2,25.1-14.7,41.2C243.2,108.6,243,108.9,242.7,108.9L242.7,108.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M252.4,73.5c-0.2,0-0.4-0.1-0.5-0.4c-3.9-13.1-15.3-23.3-28.9-26c-0.3-0.1-0.5-0.3-0.4-0.6
c0.1-0.3,0.3-0.5,0.6-0.4c14,2.7,25.7,13.2,29.7,26.7c0.1,0.3-0.1,0.6-0.4,0.7C252.5,73.5,252.4,73.5,252.4,73.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M267.2,111.9c-0.3,0-0.5-0.2-0.5-0.5c-1.8-17.5,4.6-31.2,10.3-39.6c7.3-10.8,16.5-17.4,22.1-19.9
c0.3-0.1,0.6,0,0.7,0.3c0.1,0.3,0,0.6-0.3,0.7c-5.5,2.4-14.4,8.9-21.6,19.5c-5.6,8.2-11.9,21.7-10.1,38.9
C267.7,111.6,267.5,111.8,267.2,111.9C267.2,111.9,267.2,111.9,267.2,111.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M243.3,97.6C243.3,97.6,243.3,97.6,243.3,97.6c-0.3,0-0.6-0.3-0.5-0.6c1.8-18.3-5.9-37.3-20.1-49.6
c-0.2-0.2-0.2-0.5-0.1-0.7c0.2-0.2,0.5-0.2,0.7-0.1c14.4,12.5,22.2,31.9,20.4,50.5C243.8,97.4,243.6,97.6,243.3,97.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M252.3,73.5c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.3-0.5-0.1-0.7c2.3-3.5,5.4-10.5,7.3-19.8
c1.4-6.9,2.4-17.1-0.4-26.8c-0.1-0.3,0.1-0.6,0.4-0.7c0.3-0.1,0.6,0.1,0.7,0.4c5.3,18.1-1.9,39.8-7,47.5
C252.6,73.4,252.4,73.5,252.3,73.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M260.7,34.6c-0.3,0-0.5-0.2-0.5-0.4c-0.4-2.3-3-5.5-7.1-7.2c-0.3-0.1-0.4-0.4-0.3-0.7
c0.1-0.3,0.4-0.4,0.7-0.3c3.1,1.3,5.8,3.6,7,6c0.6-2.5,2.1-4.9,3.1-6.1c0.2-0.2,0.5-0.3,0.7-0.1c0.2,0.2,0.3,0.5,0.1,0.7
c-1.2,1.4-3.1,4.8-3.2,7.6C261.2,34.4,261,34.6,260.7,34.6C260.7,34.6,260.7,34.6,260.7,34.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M261,40.5c-0.2,0-0.4-0.2-0.5-0.4c-1.6-5.5-5.4-9.4-11.8-12.3c-0.3-0.1-0.4-0.4-0.3-0.7
c0.1-0.3,0.4-0.4,0.7-0.3c3.1,1.4,5.6,3.1,7.6,5c1.7,1.7,3,3.6,4,5.8c1-4.6,4.4-9.2,6.9-11.2c0.2-0.2,0.6-0.1,0.7,0.1
c0.2,0.2,0.1,0.6-0.1,0.7c-2.8,2.3-6.7,7.9-6.7,12.7C261.5,40.3,261.3,40.5,261,40.5C261,40.5,261,40.5,261,40.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M260.8,45.3c-0.2,0-0.4-0.2-0.5-0.4c-1.8-5.9-8.5-12.3-16.3-15.6c-0.3-0.1-0.4-0.4-0.3-0.7
c0.1-0.3,0.4-0.4,0.7-0.3c3.8,1.6,7.6,4,10.6,6.9c2.6,2.5,4.6,5.1,5.7,7.8c1.6-5.5,5.5-10.6,11.7-15.5c0.2-0.2,0.6-0.1,0.7,0.1
c0.2,0.2,0.1,0.6-0.1,0.7c-6.6,5.3-10.5,10.7-11.8,16.5C261.3,45.1,261.1,45.3,260.8,45.3L260.8,45.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M259.8,52.2C259.8,52.2,259.8,52.2,259.8,52.2c-0.3-0.1-0.5-0.3-0.5-0.5c0-3.1-2.2-7.3-6.1-11.4
c-3.9-4.1-8.7-7.4-13.3-9.1c-0.3-0.1-0.4-0.4-0.3-0.7c0.1-0.3,0.4-0.4,0.7-0.3c4.7,1.7,9.7,5.1,13.7,9.3c3.3,3.5,5.4,7,6.1,10
c3.4-7.9,9.6-15.3,17-20.2c0.2-0.2,0.6-0.1,0.7,0.2c0.2,0.2,0.1,0.6-0.2,0.7c-7.9,5.2-14.4,13.3-17.4,21.7
C260.3,52,260.1,52.2,259.8,52.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M258.8,57.6c0,0-0.1,0-0.1,0c-0.2,0-0.4-0.2-0.4-0.5c-0.5-8.3-8.9-17.4-21.9-23.9
c-0.3-0.1-0.4-0.4-0.2-0.7c0.1-0.3,0.4-0.4,0.7-0.2c6.5,3.3,12.1,7.3,16,11.6c3.4,3.8,5.6,7.7,6.3,11.4
c2.5-4.7,6.1-9.5,10.4-13.9c3.9-4,8.2-7.5,11.9-9.7c0.3-0.1,0.6-0.1,0.7,0.2c0.1,0.3,0.1,0.6-0.2,0.7c-6,3.5-16.9,13-22.7,24.7
C259.1,57.5,259,57.6,258.8,57.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M257,63.1c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.4-0.3-0.4-0.6c0.4-3.9-1.9-8.9-6.2-13.9
c-4.6-5.2-10.8-9.7-17.6-12.7c-0.3-0.1-0.4-0.4-0.3-0.7c0.1-0.3,0.4-0.4,0.7-0.3c6.9,3,13.3,7.6,18,12.9
c3.8,4.4,6.1,8.9,6.5,12.7c8-13.5,20.4-21.1,29.4-25.1c0.3-0.1,0.6,0,0.7,0.3c0.1,0.3,0,0.6-0.3,0.7c-9.3,4.1-22.1,12-29.9,26.4
C257.4,63,257.2,63.1,257,63.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M255,68.3c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.4-0.3-0.4-0.6c0.9-6.4-3.2-12.4-6.9-16.3
c-4.7-5-11.5-9.5-18.5-12.3c-0.3-0.1-0.4-0.4-0.3-0.7c0.1-0.3,0.4-0.4,0.7-0.3c7.2,2.9,14.1,7.4,18.9,12.6
c4.7,5,7.2,10,7.3,14.8c3.2-5.3,8.5-10.6,15.4-15.5c6.2-4.4,13.5-8.3,20.1-10.7c0.3-0.1,0.6,0,0.7,0.3c0.1,0.3,0,0.6-0.3,0.7
c-9.4,3.5-29,13.8-36,27.7C255.4,68.2,255.2,68.3,255,68.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M253.8,70.9c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.4-0.3-0.4-0.5c0.2-4.6-2.6-10.2-7.6-15.3
c-5.2-5.3-12.1-9.6-19.5-12c-0.3-0.1-0.4-0.4-0.3-0.7c0.1-0.3,0.4-0.4,0.7-0.3c14.6,4.8,26.8,16.6,27.7,26.7
c3.8-5.4,9.5-10.4,16.5-14.5c7.5-4.5,16.1-7.7,24.8-9.3c0.3-0.1,0.6,0.1,0.6,0.4c0.1,0.3-0.1,0.6-0.4,0.6
c-17.7,3.4-34,13.1-41.6,24.8C254.2,70.8,254,70.9,253.8,70.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M301.2,58.4C301.2,58.4,301.2,58.4,301.2,58.4c-2.8-0.3-5.9-0.7-9.5-1c-0.1,0-0.3-0.1-0.4-0.2
c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-1,0.2-1.7,0.4-2.4c0.1-0.6,0.2-1.2,0.3-2.1c0-0.3,0.3-0.5,0.6-0.5c0.3,0,0.5,0.3,0.5,0.6
c-0.1,0.8-0.2,1.4-0.3,2.1c-0.1,0.5-0.2,1.1-0.3,1.8c3.4,0.3,6.3,0.7,8.9,1c0.3,0,0.5,0.3,0.5,0.6
C301.7,58.2,301.5,58.4,301.2,58.4z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M301.6,61.9C301.5,61.9,301.5,61.8,301.6,61.9c-4.2-0.5-7.9-0.8-11.2-1.1c-0.8-0.1-1.7-0.2-2.5-0.2
c-0.3,0-0.5-0.3-0.5-0.6c0.1-1.4,0.2-2.5,0.3-3.6c0.1-1.1,0.2-2.2,0.3-3.5c0-0.3,0.3-0.5,0.6-0.5c0.3,0,0.5,0.3,0.5,0.6
c-0.1,1.3-0.2,2.4-0.3,3.5c-0.1,1-0.2,1.9-0.2,3.1c0.6,0.1,1.3,0.1,1.9,0.2c3.3,0.3,7.1,0.6,11.2,1.1c0.3,0,0.5,0.3,0.5,0.6
C302.1,61.7,301.8,61.9,301.6,61.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M302.5,65.5L302.5,65.5c-4.2-0.1-7.4-0.3-10.5-0.5c-2.8-0.2-5.5-0.3-8.3-0.4c-0.1,0-0.3-0.1-0.4-0.2
c-0.1-0.1-0.1-0.3-0.1-0.4c0.3-1.9,0.4-3.8,0.5-5.5c0.1-1.8,0.2-3.6,0.5-5.6c0-0.3,0.3-0.5,0.6-0.5c0.3,0,0.5,0.3,0.5,0.6
c-0.3,1.9-0.4,3.8-0.5,5.5c-0.1,1.6-0.2,3.3-0.4,5c2.7,0.1,5.2,0.2,7.8,0.4c3.1,0.2,6.4,0.3,10.5,0.5c0.3,0,0.5,0.3,0.5,0.5
C303.1,65.3,302.8,65.5,302.5,65.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M302.3,69.7c-1.1,0-3.8-0.2-7.8-0.4c-5-0.3-11.3-0.7-14.2-0.6c-0.1,0-0.3-0.1-0.4-0.2
c-0.1-0.1-0.2-0.2-0.1-0.4c0.1-2.3,0.2-4.6,0.2-6.7c0-2.4,0.1-4.9,0.2-7.6c0-0.3,0.3-0.5,0.6-0.5c0.3,0,0.5,0.3,0.5,0.6
c-0.1,2.6-0.2,5.1-0.2,7.5c0,2-0.1,4.1-0.2,6.2c3.1,0,9,0.4,13.7,0.6c3.8,0.2,7.4,0.4,8.1,0.4c0.3,0,0.5,0.2,0.6,0.5
s-0.2,0.5-0.5,0.6C302.6,69.7,302.5,69.7,302.3,69.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M302.4,74.2L302.4,74.2c-0.2,0-17.8-1-25.4-1.1c-0.3,0-0.5-0.2-0.5-0.5c0-1.8,0-3.7-0.1-5.5
c-0.1-3.9-0.1-8,0-12c0-0.3,0.3-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5c-0.1,4,0,8.1,0,12c0,1.6,0,3.3,0.1,4.9
c7.8,0.2,24.7,1.1,24.9,1.1c0.3,0,0.5,0.3,0.5,0.6C302.9,74,302.7,74.2,302.4,74.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M301.6,79L301.6,79L274,78c-0.3,0-0.5-0.2-0.5-0.5c0-0.1-0.7-13.7-1-21c0-0.3,0.2-0.5,0.5-0.6
c0.3,0,0.5,0.2,0.6,0.5c0.3,6.5,0.9,18,1,20.5l27.1,1c0.3,0,0.5,0.3,0.5,0.5C302.1,78.8,301.9,79,301.6,79z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M271.2,84c-0.3,0-0.5-0.2-0.5-0.5c0-0.2-1.4-16.3-2.2-24.9c0-0.3,0.2-0.5,0.5-0.6c0.3,0,0.5,0.2,0.6,0.5
c0.7,7.8,1.9,21.6,2.1,24.4c3.1-0.1,19.5-0.5,28.7-0.4c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5l0,0
C290.3,83.4,271.4,84,271.2,84L271.2,84z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M268.9,90.6c-0.3,0-0.5-0.2-0.5-0.5c-0.8-6-1.6-11-2.5-16.4c-0.6-3.9-1.3-8-1.9-12.4
c0-0.3,0.2-0.6,0.4-0.6c0.3,0,0.6,0.2,0.6,0.4c0.7,4.5,1.3,8.5,1.9,12.4c0.8,5.2,1.6,10.1,2.4,15.9c5.2-0.5,10.4-0.8,16-1
c4.2-0.2,8.5-0.4,12.9-0.7c0.3,0,0.5,0.2,0.6,0.5c0,0.3-0.2,0.5-0.5,0.6c-4.4,0.3-8.7,0.5-12.9,0.7
C279.7,89.7,274.3,90,268.9,90.6C269,90.6,268.9,90.6,268.9,90.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M267.5,97c-0.2,0-0.4-0.2-0.5-0.4c0,0,0,0,0,0c-1.4-5.7-2.7-10.3-3.9-14.9c-1.4-5.2-2.7-10.1-4.2-16.3
c-0.1-0.3,0.1-0.6,0.4-0.6c0.3-0.1,0.6,0.1,0.6,0.4c1.5,6.2,2.8,11.1,4.2,16.3c1.2,4.4,2.4,9,3.7,14.4c0.3,0,0.6-0.1,1.1-0.1
c0.9-0.1,2.3-0.2,3.9-0.4c5.9-0.5,15.7-1.5,22-2.3c0.3,0,0.6,0.2,0.6,0.5c0,0.3-0.2,0.6-0.5,0.6c-6.3,0.8-16.2,1.7-22.1,2.3
c-2.6,0.2-4.9,0.5-5.3,0.5c0,0,0,0,0,0C267.6,97,267.6,97,267.5,97z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M266.8,103.3c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-7.7-18.9-12.9-31.9c-0.1-0.3,0-0.6,0.3-0.7
c0.3-0.1,0.6,0,0.7,0.3c4.8,12,11.6,28.8,12.7,31.5c3.6-0.5,7-0.8,10.6-1.1c3.4-0.3,6.9-0.6,11-1.1c0.3,0,0.6,0.2,0.6,0.5
c0,0.3-0.2,0.6-0.5,0.6c-4.2,0.5-7.7,0.8-11.1,1.1C274.1,102.6,270.6,102.9,266.8,103.3C266.9,103.3,266.9,103.3,266.8,103.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M266.9,109.2c-0.2,0-0.4-0.1-0.5-0.3c-1.6-3.1-4.9-8.5-8.4-14.2c-3.8-6.3-7.8-12.8-9.4-16
c-0.1-0.3,0-0.6,0.2-0.7c0.3-0.1,0.6,0,0.7,0.2c1.6,3.1,5.5,9.6,9.3,15.9c3.5,5.8,6.8,11.2,8.5,14.3c0.1,0.3,0,0.6-0.2,0.7
C267,109.2,267,109.2,266.9,109.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M264.4,111.9c-0.2,0-0.3-0.1-0.4-0.2c-2.6-3.8-5.5-7.4-8.6-11.2c-3.3-4.2-6.7-8.4-10.1-13.2
c-0.2-0.2-0.1-0.6,0.1-0.7c0.2-0.2,0.6-0.1,0.7,0.1c3.4,4.8,6.8,9,10,13.2c3.1,3.9,5.9,7.5,8.6,11.3c0.2,0.2,0.1,0.6-0.1,0.7
C264.6,111.9,264.5,111.9,264.4,111.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M258.9,112.3c-0.2,0-0.3-0.1-0.4-0.2c-2.7-3.2-5.9-6.7-8.9-10.1c-2.1-2.3-4.3-4.8-6.3-7.1
c-0.2-0.2-0.2-0.6,0.1-0.7c0.2-0.2,0.6-0.2,0.7,0.1c2,2.3,4.2,4.7,6.3,7c3.1,3.4,6.2,6.9,8.9,10.1c0.2,0.2,0.2,0.6-0.1,0.7
C259.2,112.2,259,112.3,258.9,112.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M220.6,52.2c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.8,0,1.6,0,2.5,0c1.3,0,2.7-0.1,4.1,0
c0-0.4,0-0.8,0-1.2c0-0.6,0-1.3,0.1-2c0-0.3,0.3-0.5,0.6-0.5c0.3,0,0.5,0.3,0.5,0.6c-0.1,0.7-0.1,1.3-0.1,1.9
c0,0.5,0,1.1-0.1,1.7c0,0.3-0.3,0.5-0.5,0.5l0,0c-1.6,0-3.2,0-4.6,0C222.3,52.1,221.4,52.1,220.6,52.2L220.6,52.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M219.3,55.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.6l1.2-0.1c2.8-0.2,5.8-0.4,9-0.7
c0-0.7,0.1-1.4,0.1-2.1c0.1-1,0.1-1.9,0.1-2.9c0-0.3,0.2-0.5,0.5-0.5h0c0.3,0,0.5,0.2,0.5,0.5c0,1,0,1.9-0.1,2.9
c0,0.8-0.1,1.6-0.1,2.5c0,0.3-0.2,0.5-0.5,0.5c-3.4,0.3-6.5,0.5-9.5,0.7L219.3,55.5C219.3,55.5,219.3,55.5,219.3,55.5z
M230,54.2L230,54.2L230,54.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M218.3,58.6c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.6c2.4-0.2,4.9-0.3,7.4-0.4
c2.1-0.1,4.2-0.2,6.2-0.4c0-0.5,0-1.1,0-1.6c0-1.8,0-3.8,0.2-5.4c0-0.3,0.3-0.5,0.6-0.5c0.3,0,0.5,0.3,0.5,0.6
c-0.1,1.5-0.2,3.5-0.2,5.3c0,0.8,0,1.5,0,2.1c0,0.3-0.2,0.5-0.5,0.5c-2.2,0.2-4.5,0.3-6.7,0.4C223.3,58.3,220.7,58.4,218.3,58.6
C218.3,58.6,218.3,58.6,218.3,58.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M226.3,61.8c-2.9,0-5.7,0-8.9-0.1c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5l0,0
c5.8,0.1,10.5,0.1,16.9,0c0-1.8,0.2-3.4,0.3-5.1c0.1-1.4,0.2-2.7,0.2-4c0-0.3,0.2-0.5,0.5-0.5l0,0c0.3,0,0.5,0.3,0.5,0.5
c0,1.4-0.1,2.7-0.3,4.1c-0.1,1.7-0.3,3.6-0.3,5.5c0,0.3-0.2,0.5-0.5,0.5C231.7,61.7,229,61.8,226.3,61.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M237.7,66.7L237.7,66.7c-3.5-0.3-6.8-0.5-10-0.7c-3.7-0.2-7.2-0.5-10.7-0.8c-0.3,0-0.5-0.3-0.5-0.6
c0-0.3,0.3-0.5,0.6-0.5c3.5,0.3,7,0.5,10.7,0.8c3.1,0.2,6.2,0.4,9.5,0.7c0.3-3.9,0.7-8,1.2-12c0-0.3,0.3-0.5,0.6-0.5
c0.3,0,0.5,0.3,0.5,0.6c-0.5,4.2-0.9,8.5-1.2,12.5C238.2,66.5,237.9,66.7,237.7,66.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M239.8,71.9L239.8,71.9c-6.8-0.4-14.3-0.9-23-1.5c-0.3,0-0.5-0.3-0.5-0.6c0-0.3,0.3-0.5,0.6-0.5
c8.5,0.6,15.8,1.1,22.4,1.5c0.3-2.4,0.7-4.7,1-7c0.4-2.4,0.8-4.9,1.1-7.4c0-0.3,0.3-0.5,0.6-0.5c0.3,0,0.5,0.3,0.5,0.6
c-0.3,2.5-0.7,5-1.1,7.5c-0.4,2.4-0.8,4.9-1.1,7.4C240.3,71.7,240.1,71.9,239.8,71.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M241.6,77.2c0,0-0.1,0-0.2,0c-0.3-0.1-0.4-0.4-0.4-0.7c1.5-4.9,2.8-9,4.3-13.7l0.6-1.7
c0.1-0.3,0.4-0.4,0.7-0.3c0.3,0.1,0.4,0.4,0.3,0.7l-0.6,1.7c-1.5,4.7-2.8,8.8-4.3,13.7C242,77,241.8,77.2,241.6,77.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M241.2,77.1C241.1,77.1,241.1,77.1,241.2,77.1c-4.4-0.5-8.7-1-13.2-1.6c-3.5-0.4-7.2-0.9-11-1.3
c-0.3,0-0.5-0.3-0.5-0.6c0-0.3,0.3-0.5,0.6-0.5c3.8,0.4,7.4,0.9,11,1.3c4.5,0.6,8.8,1.1,13.1,1.5c0.3,0,0.5,0.3,0.5,0.6
C241.7,76.9,241.4,77.1,241.2,77.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M242.8,83.1C242.8,83.1,242.8,83.1,242.8,83.1c-4.2-0.6-8.3-1.3-12.2-2c-4.1-0.7-8.4-1.4-12.8-2
c-0.3,0-0.5-0.3-0.4-0.6c0-0.3,0.3-0.5,0.6-0.4c4.4,0.6,8.7,1.4,12.8,2.1c3.8,0.6,7.7,1.3,11.7,1.9c1.2-3,2.4-5.5,3.6-8
c1.1-2.2,2.2-4.5,3.3-7.2c0.1-0.3,0.4-0.4,0.7-0.3c0.3,0.1,0.4,0.4,0.3,0.7c-1.1,2.7-2.2,5-3.3,7.3c-1.3,2.7-2.5,5.2-3.7,8.3
C243.2,83,243,83.1,242.8,83.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M243.4,88.8c0,0-0.1,0-0.1,0c-4.5-0.9-8.6-1.7-12.6-2.5c-3.9-0.8-7.6-1.5-11.5-2.3
c-0.3-0.1-0.5-0.3-0.4-0.6c0.1-0.3,0.3-0.5,0.6-0.4c3.9,0.8,7.6,1.5,11.5,2.3c4,0.8,8.2,1.6,12.6,2.5c0.3,0.1,0.5,0.3,0.4,0.6
C243.9,88.6,243.7,88.8,243.4,88.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M243.4,93.6c0,0-0.1,0-0.1,0c-3.2-0.7-6.4-1.4-9.5-2c-4.2-0.9-8.2-1.7-12.3-2.6
c-0.3-0.1-0.5-0.4-0.4-0.6c0.1-0.3,0.4-0.5,0.6-0.4c4.1,1,8.1,1.8,12.3,2.6c3.1,0.6,6.3,1.3,9.6,2c0.3,0.1,0.5,0.3,0.4,0.6
C243.8,93.5,243.6,93.6,243.4,93.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M243,99.2c-0.1,0-0.1,0-0.2,0c-3.5-1.1-6.6-2-9.9-3c-2.7-0.8-5.5-1.7-8.8-2.7c-0.3-0.1-0.4-0.4-0.4-0.7
c0.1-0.3,0.4-0.4,0.7-0.4c3.2,1,6,1.8,8.8,2.7c3.3,1,6.5,2,10,3c0.3,0.1,0.4,0.4,0.3,0.7C243.4,99,243.2,99.2,243,99.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M249.8,30.3c-0.3,0-0.5-0.2-0.6-0.5c-0.1-0.3,0.1-0.5,0.3-0.6c0.2-0.1,0.4-0.1,0.6,0.1
c0.2,0.1,0.3,0.3,0.3,0.5C250.3,30.1,250.1,30.3,249.8,30.3L249.8,30.3z M249.2,29.7L249.2,29.7L249.2,29.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M252.5,32h-0.1c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h0.1c0.3,0,0.5,0.2,0.5,0.5S252.8,32,252.5,32z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M255,34.4c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C255.5,34.2,255.3,34.4,255,34.4z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M257.4,37c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C257.9,36.8,257.7,37,257.4,37z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M263.7,35.5c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.3-0.5-0.1-0.7c0.1-0.2,0.3-0.3,0.5-0.3
c0.2,0,0.4,0.1,0.5,0.2c0.1,0.2,0.1,0.5-0.1,0.7C263.9,35.5,263.8,35.5,263.7,35.5z M263.3,35.2L263.3,35.2L263.3,35.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M265.2,32.8c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C265.7,32.6,265.5,32.8,265.2,32.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M267.2,30.7c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C267.5,30.7,267.4,30.7,267.2,30.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M263.1,42.5c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5V42
C263.7,42.3,263.4,42.5,263.1,42.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M264.6,39.9c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C265.2,39.7,264.9,39.9,264.6,39.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M266.4,37.4c-0.1,0-0.3-0.1-0.4-0.2c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.3,0.1,0.4,0.2
c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4C266.7,37.3,266.5,37.4,266.4,37.4z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M268.4,35.1c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C268.9,34.9,268.7,35.1,268.4,35.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M270.7,32.9c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C271,32.9,270.8,32.9,270.7,32.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M273.3,30.9c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.1
c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1
c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.5,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1C273.6,30.8,273.4,30.9,273.3,30.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M244.9,31.8c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C245.2,31.7,245.1,31.8,244.9,31.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M247.7,33.3c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C248,33.2,247.9,33.3,247.7,33.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M250.1,35c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5
C250.6,34.8,250.4,35,250.1,35z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M252.7,37c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0
C253.2,36.8,253,37,252.7,37z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M255,39.5c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5V39
C255.5,39.3,255.3,39.5,255,39.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M257.2,42c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0
C257.8,41.8,257.5,42,257.2,42z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M258.9,44.7c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C259.1,44.6,259,44.7,258.9,44.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M277.2,33c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C277.5,32.9,277.3,33,277.2,33z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M274.5,35.1c-0.1,0-0.2,0-0.2-0.1c-0.3-0.1-0.4-0.4-0.2-0.7l0.1-0.1c0.1-0.3,0.4-0.4,0.7-0.2
c0.3,0.1,0.4,0.4,0.2,0.7l-0.1,0.1C274.9,35,274.7,35.1,274.5,35.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M272,37.4c-0.3,0-0.5-0.2-0.5-0.5v0v0v0v0c0-0.3,0.2-0.5,0.5-0.5c0.1,0,0.2,0,0.3,0.1
c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.2,0.4-0.4,0.5C272.1,37.4,272,37.4,272,37.4z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M269.7,39.6c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5
C270.2,39.4,270,39.6,269.7,39.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M267.4,42.1c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C267.7,42,267.5,42.1,267.4,42.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M265.3,44.7c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C265.6,44.6,265.5,44.7,265.3,44.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M263.5,47.3c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C264.1,47.1,263.8,47.3,263.5,47.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M258.1,50.4c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C258.4,50.3,258.3,50.4,258.1,50.4z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M257,48c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C257.3,48,257.1,48,257,48z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M255.4,45.6c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C255.9,45.4,255.7,45.6,255.4,45.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M253.4,42.9L253.4,42.9c-0.3,0-0.5-0.2-0.6-0.4c-0.1-0.3,0.1-0.5,0.4-0.6c0.1,0,0.1,0,0.2,0
c0.1,0,0.1,0,0.2,0c0.3,0.1,0.5,0.3,0.4,0.6C254,42.7,253.7,42.9,253.4,42.9z M253.8,42.3l0.1,0L253.8,42.3z M253.1,42.3
L253.1,42.3L253.1,42.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M251.4,40.8c-0.1,0-0.3,0-0.4-0.1l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C251.7,40.7,251.6,40.8,251.4,40.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M248.9,38.7c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C249.4,38.5,249.2,38.7,248.9,38.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M246.7,37c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.3-0.5-0.1-0.7c0.1-0.2,0.3-0.3,0.5-0.3
c0.2,0,0.4,0.1,0.5,0.2c0.1,0.2,0.1,0.5-0.1,0.7C246.9,36.9,246.8,37,246.7,37z M246.2,36.7L246.2,36.7L246.2,36.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M244,35.3c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.4-0.4-0.3-0.7c0-0.1,0.1-0.2,0.2-0.2
c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.1,0.6,0,0.7,0.3c0.1,0.2,0.1,0.5-0.1,0.7C244.3,35.2,244.2,35.3,244,35.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M241.2,33.7c-0.3,0-0.5-0.2-0.5-0.5V33c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0.1
C241.8,33.4,241.5,33.7,241.2,33.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M267.6,47.1c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C267.9,47.1,267.7,47.1,267.6,47.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M269.9,44.9c-0.3,0-0.5-0.3-0.5-0.6c0-0.3,0.2-0.6,0.5-0.6h0c0.3,0,0.5,0.3,0.5,0.6
C270.4,44.7,270.1,44.9,269.9,44.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M271.8,42.9c-0.3,0-0.5-0.3-0.5-0.6c0-0.3,0.2-0.6,0.5-0.6h0c0.3,0,0.5,0.3,0.5,0.6
C272.4,42.7,272.1,42.9,271.8,42.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M274,41.5c-0.2,0-0.4-0.1-0.5-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.3,0.1-0.5,0.4-0.6
c0.3-0.1,0.6,0.1,0.7,0.3c0.1,0.3,0,0.6-0.3,0.7C274.2,41.5,274.1,41.5,274,41.5z M274.5,40.9L274.5,40.9L274.5,40.9z
M274.5,40.7L274.5,40.7L274.5,40.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M276.3,39.8c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C276.8,39.5,276.6,39.8,276.3,39.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M278.8,38.2L278.8,38.2c-0.3,0-0.6-0.2-0.6-0.5c0-0.3,0.2-0.5,0.5-0.5h0c0.3,0,0.5,0.2,0.5,0.5
C279.4,37.9,279.1,38.2,278.8,38.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M281.4,36.3h-0.1c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h0.1c0.3,0,0.5,0.2,0.5,0.5
C281.9,36.1,281.6,36.3,281.4,36.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M268.1,51.3c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C268.4,51.2,268.3,51.3,268.1,51.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M270.1,49.5c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0
C270.7,49.3,270.4,49.5,270.1,49.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M272.4,47.8c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C272.7,47.7,272.6,47.8,272.4,47.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M275.1,46.1c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C275.6,45.9,275.4,46.1,275.1,46.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M277.8,44.3c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C278.1,44.3,278,44.3,277.8,44.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M280.5,42.8c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0.1
C281,42.6,280.8,42.8,280.5,42.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M283.3,41.2c-0.3,0-0.6-0.2-0.6-0.5v0c0-0.3,0.3-0.5,0.6-0.5s0.5,0.2,0.5,0.5
C283.8,41,283.6,41.2,283.3,41.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M286.2,39.9c-0.3,0-0.5-0.2-0.5-0.4l0-0.1c0-0.3,0.1-0.6,0.4-0.6c0.3,0,0.6,0.1,0.6,0.4l0,0.1
C286.8,39.6,286.6,39.8,286.2,39.9C286.3,39.9,286.2,39.9,286.2,39.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M269.7,54.2c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C270.3,54,270,54.2,269.7,54.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M272.3,52.6c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C272.8,52.4,272.6,52.6,272.3,52.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M274.7,51.1c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C275,51,274.9,51.1,274.7,51.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M277.5,49.6c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0
C278.1,49.4,277.8,49.6,277.5,49.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M280.3,48.3c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C280.6,48.3,280.4,48.3,280.3,48.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M283.2,47c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C283.5,46.9,283.3,47,283.2,47z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M286.3,45.8L286.3,45.8c-0.3,0-0.6-0.2-0.6-0.5c0-0.3,0.2-0.5,0.5-0.5h0c0.3,0,0.5,0.2,0.5,0.5
C286.8,45.6,286.6,45.8,286.3,45.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M289.8,44.6c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C290,44.5,289.9,44.6,289.8,44.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M264.4,28.8h-0.1c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h0.1c0.3,0,0.5,0.2,0.5,0.5
S264.7,28.8,264.4,28.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M254.4,29.3h-0.1c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h0.1c0.3,0,0.5,0.2,0.5,0.5
S254.7,29.3,254.4,29.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M257,31.4c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.3-0.2-0.5c0-0.2,0.1-0.4,0.3-0.5
c0.2-0.1,0.5-0.1,0.7,0.1c0.2,0.2,0.2,0.5,0,0.7C257.3,31.3,257.1,31.4,257,31.4z M257.2,30.4L257.2,30.4L257.2,30.4z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M277.7,53.7c-0.3,0-0.5-0.2-0.5-0.5V53c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C278.2,53.4,277.9,53.7,277.7,53.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M280.8,52.6L280.8,52.6c-0.3,0-0.6-0.2-0.6-0.5c0-0.3,0.2-0.5,0.5-0.5h0c0.3,0,0.5,0.2,0.5,0.5
C281.4,52.3,281.1,52.6,280.8,52.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M284.1,51.6c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C284.3,51.6,284.2,51.6,284.1,51.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M287,50.8c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0.1-0.1,0.3-0.2,0.5-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1
c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
C287.1,50.8,287.1,50.8,287,50.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M290.2,50.1c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0
C290.7,49.9,290.5,50.1,290.2,50.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M293.7,49.6c-0.1,0-0.2,0-0.2-0.1c-0.3-0.1-0.4-0.5-0.2-0.7l0.1-0.1c0.1-0.3,0.5-0.4,0.7-0.2
c0.3,0.1,0.4,0.5,0.2,0.7l-0.1,0.1C294,49.5,293.9,49.6,293.7,49.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M237.3,36c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7L237,35c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C237.6,35.9,237.4,36,237.3,36z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M239.8,37.5c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5V37
C240.3,37.3,240.1,37.5,239.8,37.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M242.5,39.4c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0.1
C243,39.1,242.7,39.4,242.5,39.4z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M245.6,41.2c-0.3,0-0.5-0.2-0.6-0.4c-0.1-0.3,0.1-0.5,0.3-0.6c0.2-0.1,0.4-0.1,0.6,0.1
c0.2,0.1,0.3,0.3,0.2,0.5C246.1,41,245.9,41.2,245.6,41.2L245.6,41.2z M245.1,40.6L245.1,40.6L245.1,40.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M248,43.5c-0.3,0-0.6-0.2-0.6-0.5v0c0-0.3,0.3-0.5,0.6-0.5c0.3,0,0.5,0.2,0.5,0.5
C248.5,43.2,248.3,43.5,248,43.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M250.2,45.4c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.3-0.5-0.1-0.7c0.1-0.2,0.3-0.3,0.5-0.3
c0.2,0,0.4,0.1,0.5,0.2c0.1,0.2,0.1,0.5-0.1,0.7C250.5,45.4,250.4,45.4,250.2,45.4z M249.8,45.1L249.8,45.1L249.8,45.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M252.5,47.8c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C252.8,47.8,252.6,47.8,252.5,47.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M254.4,50.6c-0.3,0-0.5-0.2-0.5-0.5V50c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0.1
C255,50.4,254.7,50.6,254.4,50.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M256,53.2c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C256.2,53.1,256.1,53.2,256,53.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M257.1,56.2c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v0.1
C257.6,55.9,257.4,56.2,257.1,56.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M233.3,38.6c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0
C233.9,38.4,233.6,38.6,233.3,38.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M235.9,40c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5
C236.4,39.8,236.1,40,235.9,40z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M238.4,41.2c-0.3,0-0.5-0.3-0.5-0.6c0-0.3,0.2-0.6,0.5-0.6h0c0.3,0,0.5,0.3,0.5,0.6
C238.9,40.9,238.7,41.2,238.4,41.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M240.9,42.9c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.3-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5
C241.4,42.7,241.1,42.9,240.9,42.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M243.3,44.8c-0.2,0-0.4-0.1-0.5-0.3l-0.1-0.1c-0.1-0.3,0-0.6,0.2-0.7c0.3-0.1,0.6,0,0.7,0.2l0.1,0.1
c0.1,0.3,0,0.6-0.2,0.7C243.5,44.8,243.4,44.8,243.3,44.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M245.8,46.8c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C246,46.7,245.9,46.8,245.8,46.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M248.1,48.8c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C248.4,48.8,248.2,48.8,248.1,48.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M250.3,51.3c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C250.6,51.3,250.5,51.3,250.3,51.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M252.3,54.1c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0.1
C252.8,53.8,252.6,54.1,252.3,54.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M253.9,56.6c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5
C254.4,56.3,254.1,56.6,253.9,56.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M255.3,59.7c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0.1
C255.8,59.5,255.6,59.7,255.3,59.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M230,42.2c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l0.1,0.1
c0.2,0.2,0.2,0.5,0,0.7C230.3,42.1,230.2,42.2,230,42.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M232.7,43.4c-0.1,0-0.2,0-0.2-0.1c-0.3-0.1-0.4-0.4-0.2-0.7l0.1-0.1c0.1-0.3,0.4-0.4,0.7-0.2
c0.3,0.1,0.4,0.4,0.2,0.7l-0.1,0.1C233,43.3,232.9,43.4,232.7,43.4z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M235.6,44.8c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5l0.1,0h0c0.3,0,0.5,0.3,0.5,0.5
c0,0.3-0.2,0.5-0.5,0.5L235.6,44.8L235.6,44.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M238.5,46.4c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h0c0.3,0,0.5,0.2,0.5,0.5
C239,46.1,238.8,46.4,238.5,46.4z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M241,48.2c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0.1
C241.5,48,241.3,48.2,241,48.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M243.4,50c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5
C243.9,49.7,243.7,50,243.4,50z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M245.7,52.1c-0.3,0-0.5-0.2-0.5-0.5v-0.1c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0.1
C246.2,51.8,246,52.1,245.7,52.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M247.7,54.1c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0
C248.2,53.9,248,54.1,247.7,54.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M249.7,56.3c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C249.9,56.3,249.8,56.3,249.7,56.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M251.3,58.8c-0.3,0-0.5-0.3-0.5-0.6c0-0.3,0.2-0.6,0.5-0.6h0c0.3,0,0.5,0.3,0.5,0.6
C251.9,58.5,251.6,58.8,251.3,58.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M252.6,61.5c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C252.9,61.4,252.8,61.5,252.6,61.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M226.7,45.8c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.3,0-0.6,0.2-0.7l0.1-0.1c0.3-0.1,0.6,0,0.7,0.2
c0.1,0.3,0,0.6-0.2,0.7l-0.1,0.1C226.9,45.8,226.8,45.8,226.7,45.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M229.8,47c-0.3,0-0.5-0.2-0.5-0.4l0-0.1c0-0.3,0.1-0.6,0.4-0.6c0.3,0,0.6,0.1,0.6,0.4l0,0.1
C230.4,46.7,230.2,46.9,229.8,47C229.9,47,229.9,47,229.8,47z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M232.7,48.1c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5
C233.2,47.9,232.9,48.1,232.7,48.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M235.5,49.5c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.3-0.5-0.1-0.7l0.1-0.1c0.2-0.2,0.5-0.3,0.7-0.1
c0.2,0.2,0.3,0.5,0.1,0.7l-0.1,0.1C235.8,49.4,235.7,49.5,235.5,49.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M238.3,51.3c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5
C238.8,51,238.5,51.3,238.3,51.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M241,53.2c-0.3,0-0.5-0.2-0.5-0.4c0-0.3,0.2-0.6,0.4-0.6l0.1,0c0.3,0,0.6,0.2,0.6,0.4
c0,0.3-0.2,0.6-0.4,0.6L241,53.2C241.1,53.2,241,53.2,241,53.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M243.2,55.4c-0.3,0-0.5-0.2-0.5-0.4c0-0.3,0.2-0.6,0.4-0.6l0.1,0c0.3,0,0.6,0.2,0.6,0.4
c0,0.3-0.2,0.6-0.4,0.6L243.2,55.4C243.3,55.4,243.3,55.4,243.2,55.4z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M245.6,57.5c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.2-0.2-0.4-0.1-0.7c0.1-0.2,0.4-0.4,0.6-0.3
c0.2,0,0.4,0.2,0.5,0.5c0,0.3-0.1,0.5-0.4,0.6C245.8,57.5,245.7,57.5,245.6,57.5z M245.9,56.6L245.9,56.6L245.9,56.6z
M245.9,56.6L245.9,56.6L245.9,56.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M247.3,59.7c-0.3,0-0.6-0.2-0.6-0.5v0c0-0.3,0.3-0.5,0.6-0.5s0.5,0.2,0.5,0.5
C247.8,59.5,247.6,59.7,247.3,59.7z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M249.3,62.3c-0.2,0-0.4-0.1-0.5-0.3l-0.1-0.1c-0.1-0.3,0-0.6,0.2-0.7c0.3-0.1,0.6,0,0.7,0.2l0.1,0.1
c0.1,0.3,0,0.6-0.2,0.7C249.4,62.2,249.3,62.3,249.3,62.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M250.9,64.9c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C251.2,64.9,251.1,64.9,250.9,64.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M252,68h-0.1c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h0.1c0.3,0,0.5,0.2,0.5,0.5S252.3,68,252,68z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M274.4,54.9c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C274.7,54.9,274.5,54.9,274.4,54.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M267.2,56C267.2,56,267.1,56,267.2,56l-0.2,0c-0.3,0-0.5-0.3-0.4-0.6c0-0.3,0.3-0.5,0.6-0.4l0.1,0
c0.3,0,0.5,0.3,0.4,0.6C267.7,55.8,267.5,56,267.2,56z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M266,53.2c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.2-0.2,0.5-0.2,0.7,0
c0.2,0.2,0.2,0.5,0,0.7l-0.1,0.1C266.3,53.1,266.1,53.2,266,53.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M265.6,49.3c-0.3,0-0.5-0.3-0.5-0.6c0-0.3,0.2-0.6,0.5-0.6h0c0.3,0,0.5,0.3,0.5,0.6
C266.1,49,265.9,49.3,265.6,49.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M262.8,31.2c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.3,0-0.6,0.2-0.7l0.1-0.1c0.3-0.1,0.6,0,0.7,0.2
c0.1,0.3,0,0.6-0.2,0.7l-0.1,0.1C263,31.2,262.9,31.2,262.8,31.2z"
    />
    <line
      :fill="fill ? '#707372' : 'currentColor'"
      x1="41.4"
      y1="200.6"
      x2="41"
      y2="201.3"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M130.8,223.7V214l-4.2,7.5h-0.9l-4.2-7.5v9.7h-1.6v-12.6h1.7l4.6,8.2l4.6-8.2h1.7v12.6H130.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M141.9,223.8c-0.9,0-1.7-0.2-2.4-0.5c-0.7-0.4-1.3-0.8-1.9-1.4c-0.5-0.6-0.9-1.3-1.2-2
c-0.3-0.8-0.4-1.6-0.4-2.4c0-0.8,0.2-1.6,0.5-2.4c0.3-0.8,0.7-1.4,1.2-2c0.5-0.6,1.2-1.1,1.9-1.4c0.7-0.3,1.5-0.5,2.4-0.5
c0.9,0,1.7,0.2,2.4,0.6c0.7,0.4,1.3,0.9,1.9,1.5c0.5,0.6,0.9,1.3,1.2,2c0.3,0.8,0.4,1.5,0.4,2.3c0,0.8-0.2,1.6-0.5,2.4
c-0.3,0.8-0.7,1.4-1.2,2s-1.1,1.1-1.9,1.4C143.6,223.7,142.8,223.8,141.9,223.8z M137.6,217.5c0,0.6,0.1,1.3,0.3,1.9
s0.5,1.1,0.9,1.6c0.4,0.5,0.8,0.8,1.4,1.1c0.5,0.3,1.1,0.4,1.8,0.4c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,1-0.7,1.4-1.1
c0.4-0.5,0.7-1,0.9-1.6c0.2-0.6,0.3-1.2,0.3-1.8c0-0.6-0.1-1.3-0.3-1.9c-0.2-0.6-0.5-1.1-0.9-1.6c-0.4-0.5-0.8-0.8-1.4-1.1
c-0.5-0.3-1.1-0.4-1.7-0.4c-0.7,0-1.3,0.1-1.8,0.4c-0.5,0.3-1,0.7-1.4,1.1c-0.4,0.5-0.7,1-0.9,1.6
C137.7,216.2,137.6,216.8,137.6,217.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M153.1,214.1v9.6h-1.6v-12.6h1.2l7.8,9.8v-9.8h1.6v12.6h-1.3L153.1,214.1z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M175.6,212.6h-4.3v11.2h-1.6v-11.2h-4.3v-1.4h10.3V212.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M187.4,222.3v1.4h-8.5v-12.6h8.4v1.4h-6.8v4.1h5.9v1.3h-5.9v4.3H187.4z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M199.6,222c-1.1,1.2-2.4,1.8-3.8,1.8c-0.8,0-1.6-0.2-2.3-0.5c-0.7-0.4-1.3-0.8-1.9-1.4
c-0.5-0.6-0.9-1.3-1.2-2c-0.3-0.8-0.5-1.6-0.5-2.4c0-0.9,0.1-1.7,0.4-2.4c0.3-0.8,0.7-1.4,1.2-2c0.5-0.6,1.1-1,1.9-1.4
c0.7-0.3,1.5-0.5,2.3-0.5c1.2,0,2.2,0.3,3,0.8c0.8,0.5,1.4,1.2,1.8,2l-1.2,0.9c-0.4-0.7-0.9-1.3-1.5-1.6
c-0.6-0.4-1.3-0.5-2.1-0.5c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-1,0.6-1.3,1.1c-0.4,0.5-0.6,1-0.9,1.6c-0.2,0.6-0.3,1.2-0.3,1.9
c0,0.7,0.1,1.3,0.3,1.9c0.2,0.6,0.5,1.1,0.9,1.6c0.4,0.5,0.9,0.8,1.4,1.1c0.5,0.3,1.1,0.4,1.7,0.4c1.4,0,2.6-0.7,3.7-2v-1.7h-2.7
v-1.2h4.1v6.3h-1.3V222z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M209.9,223.8c-0.9,0-1.7-0.2-2.4-0.5c-0.7-0.4-1.3-0.8-1.9-1.4c-0.5-0.6-0.9-1.3-1.2-2
c-0.3-0.8-0.4-1.6-0.4-2.4c0-0.8,0.2-1.6,0.5-2.4c0.3-0.8,0.7-1.4,1.2-2c0.5-0.6,1.2-1.1,1.9-1.4c0.7-0.3,1.5-0.5,2.4-0.5
c0.9,0,1.7,0.2,2.4,0.6c0.7,0.4,1.3,0.9,1.9,1.5c0.5,0.6,0.9,1.3,1.2,2c0.3,0.8,0.4,1.5,0.4,2.3c0,0.8-0.2,1.6-0.5,2.4
c-0.3,0.8-0.7,1.4-1.2,2s-1.1,1.1-1.9,1.4C211.5,223.7,210.7,223.8,209.9,223.8z M205.6,217.5c0,0.6,0.1,1.3,0.3,1.9
c0.2,0.6,0.5,1.1,0.9,1.6c0.4,0.5,0.8,0.8,1.4,1.1c0.5,0.3,1.1,0.4,1.8,0.4c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,1-0.7,1.4-1.1
c0.4-0.5,0.7-1,0.9-1.6c0.2-0.6,0.3-1.2,0.3-1.8c0-0.6-0.1-1.3-0.3-1.9s-0.5-1.1-0.9-1.6c-0.4-0.5-0.8-0.8-1.4-1.1
c-0.5-0.3-1.1-0.4-1.7-0.4c-0.7,0-1.3,0.1-1.8,0.4c-0.5,0.3-1,0.7-1.4,1.1c-0.4,0.5-0.7,1-0.9,1.6
C205.7,216.2,205.6,216.8,205.6,217.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M235,220.5c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.5,0.7-0.8,1c-0.3,0.3-0.7,0.5-1.2,0.7s-0.9,0.2-1.4,0.2h-6
v-12.6h6.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.4,0.9,0.7c0.2,0.3,0.4,0.6,0.6,1c0.1,0.4,0.2,0.8,0.2,1.2c0,0.6-0.2,1.2-0.5,1.7
c-0.3,0.5-0.8,0.9-1.3,1.2c0.7,0.2,1.3,0.6,1.7,1.2C234.8,219,235,219.7,235,220.5z M227,212.5v4.2h4c0.3,0,0.5-0.1,0.8-0.2
c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.2,0.3-0.4,0.4-0.7c0.1-0.3,0.2-0.5,0.2-0.8c0-0.3,0-0.6-0.1-0.8c-0.1-0.3-0.2-0.5-0.4-0.7
c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.2-0.7-0.2H227z M233.4,220.2c0-0.3,0-0.6-0.1-0.8c-0.1-0.3-0.2-0.5-0.4-0.7
c-0.2-0.2-0.4-0.4-0.6-0.5s-0.5-0.2-0.8-0.2H227v4.3h4.4c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.5-0.3,0.7-0.5c0.2-0.2,0.3-0.4,0.4-0.7
S233.4,220.5,233.4,220.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M242.2,211.2h1.3l5.1,12.6H247l-1.5-3.7h-5.3l-1.5,3.7h-1.7L242.2,211.2z M245.2,218.8l-2.3-5.8l-2.3,5.8
H245.2z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M250.9,211.2l3.8,6.6l3.8-6.6h1.7l-4.8,8v4.6h-1.6v-4.6l-4.8-8H250.9z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M268.7,223.7v-12.6h5.4c0.6,0,1.1,0.1,1.5,0.3c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.4,0.6,0.8,0.8,1.3
c0.2,0.5,0.3,1,0.3,1.5c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.3,0.8-0.5,1.1s-0.5,0.6-0.8,0.9c-0.3,0.2-0.7,0.4-1.1,0.5l3,4.8h-1.8
l-2.9-4.5h-3.3v4.5H268.7z M270.3,217.8h3.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.4-0.8
c0.1-0.3,0.2-0.6,0.2-1c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.1-0.6-0.2-0.9-0.2h-3.7
V217.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M290.3,222.3v1.4h-8.5v-12.6h8.4v1.4h-6.8v4.1h5.9v1.3h-5.9v4.3H290.3z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M301.3,213.8c-0.1-0.2-0.3-0.3-0.6-0.5c-0.2-0.2-0.5-0.3-0.8-0.4c-0.3-0.1-0.6-0.2-1-0.3
c-0.3-0.1-0.7-0.1-1.1-0.1c-1,0-1.7,0.2-2.2,0.5c-0.5,0.4-0.7,0.9-0.7,1.5c0,0.3,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.4,0.6,0.6
c0.3,0.2,0.6,0.3,1,0.4c0.4,0.1,0.9,0.2,1.5,0.4c0.6,0.1,1.2,0.3,1.8,0.5s1,0.4,1.3,0.7c0.4,0.3,0.7,0.6,0.9,1
c0.2,0.4,0.3,0.9,0.3,1.4c0,0.6-0.1,1.1-0.4,1.6c-0.2,0.4-0.6,0.8-1,1.1c-0.4,0.3-0.9,0.5-1.4,0.6c-0.6,0.1-1.2,0.2-1.8,0.2
c-1,0-1.9-0.1-2.8-0.5c-0.9-0.3-1.7-0.7-2.4-1.3l0.8-1.3c0.2,0.2,0.4,0.4,0.7,0.6c0.3,0.2,0.6,0.4,1,0.5c0.4,0.2,0.8,0.3,1.2,0.4
c0.4,0.1,0.9,0.1,1.4,0.1c0.9,0,1.6-0.2,2.1-0.5c0.5-0.3,0.7-0.8,0.7-1.4c0-0.4-0.1-0.7-0.2-0.9c-0.2-0.2-0.4-0.5-0.7-0.6
c-0.3-0.2-0.7-0.3-1.1-0.5c-0.5-0.1-1-0.3-1.5-0.4c-0.6-0.2-1.2-0.3-1.7-0.5c-0.5-0.2-0.9-0.4-1.2-0.6c-0.3-0.2-0.6-0.6-0.7-0.9
c-0.2-0.4-0.2-0.8-0.2-1.3c0-0.6,0.1-1.1,0.3-1.6c0.2-0.5,0.5-0.9,0.9-1.2s0.9-0.6,1.4-0.7c0.6-0.2,1.2-0.2,1.8-0.2
c0.9,0,1.6,0.1,2.3,0.4c0.7,0.3,1.3,0.6,1.8,1L301.3,213.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M311.2,223.8c-0.9,0-1.7-0.2-2.4-0.5c-0.7-0.4-1.3-0.8-1.9-1.4c-0.5-0.6-0.9-1.3-1.2-2
c-0.3-0.8-0.4-1.6-0.4-2.4c0-0.8,0.2-1.6,0.5-2.4c0.3-0.8,0.7-1.4,1.2-2c0.5-0.6,1.2-1.1,1.9-1.4c0.7-0.3,1.5-0.5,2.4-0.5
c0.9,0,1.7,0.2,2.4,0.6c0.7,0.4,1.3,0.9,1.9,1.5c0.5,0.6,0.9,1.3,1.2,2c0.3,0.8,0.4,1.5,0.4,2.3c0,0.8-0.2,1.6-0.5,2.4
c-0.3,0.8-0.7,1.4-1.2,2s-1.1,1.1-1.9,1.4C312.8,223.7,312,223.8,311.2,223.8z M306.9,217.5c0,0.6,0.1,1.3,0.3,1.9
c0.2,0.6,0.5,1.1,0.9,1.6c0.4,0.5,0.8,0.8,1.4,1.1c0.5,0.3,1.1,0.4,1.8,0.4c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,1-0.7,1.4-1.1
c0.4-0.5,0.7-1,0.9-1.6c0.2-0.6,0.3-1.2,0.3-1.8c0-0.6-0.1-1.3-0.3-1.9s-0.5-1.1-0.9-1.6c-0.4-0.5-0.8-0.8-1.4-1.1
c-0.5-0.3-1.1-0.4-1.7-0.4c-0.7,0-1.3,0.1-1.8,0.4c-0.5,0.3-1,0.7-1.4,1.1c-0.4,0.5-0.7,1-0.9,1.6
C307,216.2,306.9,216.8,306.9,217.5z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M320.7,223.7v-12.6h5.4c0.6,0,1.1,0.1,1.5,0.3c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.4,0.6,0.8,0.8,1.3
c0.2,0.5,0.3,1,0.3,1.5c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.3,0.8-0.5,1.1c-0.2,0.3-0.5,0.6-0.8,0.9c-0.3,0.2-0.7,0.4-1.1,0.5
l3,4.8h-1.8l-2.9-4.5h-3.3v4.5H320.7z M322.3,217.8h3.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.4-0.8
c0.1-0.3,0.2-0.6,0.2-1c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.1-0.6-0.2-0.9-0.2h-3.7
V217.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M342.7,212.6h-4.3v11.2h-1.6v-11.2h-4.3v-1.4h10.3V212.6z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M359.8,223.7l-1.3-1.4c-0.5,0.5-1.1,0.9-1.7,1.1c-0.6,0.3-1.3,0.4-2,0.4c-0.6,0-1.1-0.1-1.6-0.3
c-0.5-0.2-0.9-0.4-1.3-0.7c-0.4-0.3-0.7-0.7-0.9-1.1c-0.2-0.4-0.3-0.9-0.3-1.4c0-0.4,0.1-0.8,0.2-1.1c0.1-0.4,0.3-0.7,0.5-1
c0.2-0.3,0.5-0.6,0.8-0.8c0.3-0.3,0.6-0.5,0.9-0.7c-0.3-0.3-0.5-0.6-0.7-0.9c-0.2-0.3-0.4-0.5-0.5-0.7c-0.1-0.2-0.2-0.4-0.3-0.7
c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.4,0.1-0.8,0.2-1.1c0.2-0.3,0.4-0.6,0.7-0.9c0.3-0.3,0.6-0.5,1-0.6c0.4-0.1,0.8-0.2,1.3-0.2
c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,1,0.5c0.3,0.2,0.5,0.5,0.7,0.8c0.2,0.3,0.2,0.7,0.2,1.1c0,0.4-0.1,0.7-0.2,1
c-0.1,0.3-0.3,0.6-0.5,0.9c-0.2,0.3-0.5,0.5-0.8,0.8c-0.3,0.2-0.7,0.5-1,0.7l3.2,3.4c0.2-0.4,0.4-0.9,0.5-1.4
c0.1-0.5,0.2-1,0.2-1.6h1.3c0,0.8-0.1,1.5-0.3,2.1c-0.2,0.7-0.4,1.3-0.8,1.8l2.4,2.5H359.8z M354.9,222.7c0.5,0,1-0.1,1.5-0.3
c0.5-0.2,0.9-0.5,1.2-0.9l-3.6-3.8c-0.6,0.4-1,0.8-1.4,1.2c-0.3,0.4-0.5,0.9-0.5,1.5c0,0.4,0.1,0.7,0.2,1
c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.5,0.3,0.9,0.4C354.2,222.6,354.5,222.7,354.9,222.7z M353,213.8c0,0.1,0,0.3,0.1,0.4
c0,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.2,0.3,0.4,0.6c0.2,0.2,0.4,0.5,0.7,0.8c0.6-0.4,1.1-0.8,1.5-1.1c0.4-0.4,0.6-0.8,0.6-1.2
c0-0.4-0.2-0.8-0.5-1c-0.3-0.3-0.7-0.4-1.2-0.4c-0.5,0-1,0.2-1.3,0.5S353,213.4,353,213.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M378.1,213.8c-0.1-0.2-0.3-0.3-0.6-0.5c-0.2-0.2-0.5-0.3-0.8-0.4c-0.3-0.1-0.6-0.2-1-0.3
c-0.3-0.1-0.7-0.1-1.1-0.1c-1,0-1.7,0.2-2.2,0.5c-0.5,0.4-0.7,0.9-0.7,1.5c0,0.3,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.4,0.6,0.6
c0.3,0.2,0.6,0.3,1,0.4c0.4,0.1,0.9,0.2,1.5,0.4c0.6,0.1,1.2,0.3,1.8,0.5s1,0.4,1.3,0.7c0.4,0.3,0.7,0.6,0.9,1
c0.2,0.4,0.3,0.9,0.3,1.4c0,0.6-0.1,1.1-0.4,1.6s-0.6,0.8-1,1.1c-0.4,0.3-0.9,0.5-1.4,0.6c-0.6,0.1-1.2,0.2-1.8,0.2
c-1,0-1.9-0.1-2.8-0.5c-0.9-0.3-1.7-0.7-2.4-1.3l0.8-1.3c0.2,0.2,0.4,0.4,0.7,0.6c0.3,0.2,0.6,0.4,1,0.5c0.4,0.2,0.8,0.3,1.2,0.4
c0.4,0.1,0.9,0.1,1.4,0.1c0.9,0,1.6-0.2,2.1-0.5c0.5-0.3,0.7-0.8,0.7-1.4c0-0.4-0.1-0.7-0.2-0.9c-0.2-0.2-0.4-0.5-0.7-0.6
c-0.3-0.2-0.7-0.3-1.1-0.5c-0.5-0.1-1-0.3-1.5-0.4c-0.6-0.2-1.2-0.3-1.7-0.5c-0.5-0.2-0.9-0.4-1.2-0.6c-0.3-0.2-0.6-0.6-0.7-0.9
c-0.2-0.4-0.2-0.8-0.2-1.3c0-0.6,0.1-1.1,0.3-1.6c0.2-0.5,0.5-0.9,0.9-1.2s0.9-0.6,1.4-0.7c0.6-0.2,1.2-0.2,1.8-0.2
c0.9,0,1.6,0.1,2.3,0.4c0.7,0.3,1.3,0.6,1.8,1L378.1,213.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M382.9,223.7v-12.6h5.2c0.6,0,1.1,0.1,1.5,0.3c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.4,0.6,0.8,0.8,1.3
c0.2,0.5,0.3,1,0.3,1.5c0,0.5-0.1,1-0.3,1.5c-0.2,0.5-0.4,0.9-0.8,1.3c-0.3,0.4-0.7,0.7-1.2,0.9c-0.5,0.2-1,0.3-1.5,0.3h-3.8v4.5
H382.9z M384.5,217.8h3.7c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0.1-0.3,0.2-0.7,0.2-1
c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3-0.1-0.6-0.2-0.9-0.2h-3.6V217.8z"
    />
    <path
      :fill="fill ? '#707372' : 'currentColor'"
      d="M398.3,211.2h1.3l5.1,12.6h-1.7l-1.5-3.7h-5.3l-1.5,3.7h-1.7L398.3,211.2z M401.2,218.8L399,213l-2.3,5.8
H401.2z"
    />
  </svg>
</template>
